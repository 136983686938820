.fullWidthDiv {
    float: left;
    width: 100%;
}

.padding-none{
    padding: 0px !important;
}

.float-right {
    float: right;
}

.inputBoxErrorStyle {
    border-color: #ec4561 !important;
}

.helperTextErrorStyle {
    color: #ec4561 !important;
}

.defaultInputBox, .defaultSelectBox {
    background-image: none !important;
}

.selectBoxErrorStyle > div {
    border-color: #ec4561 !important;
}

.selectBoxLabelStyle {
    color: #ec4561 !important;
}
/*.selectBoxErrorStyle > span {*/
/*    border-color: #ec4561 !important;*/
/*    box-shadow: 0 0 0 0.15rem rgba(236, 69, 97, 0.25) !important;*/
/*}*/

.defaultCheckBoxWrapper {
    margin-bottom: 1rem;
}

.buttonWrapper{
    display: flex;
    flex-direction: row;
}

.defaultDatePickerStyle {
    max-width: 100% !important;
    background-image: none !important;
}

.reactDatePickerWrapper {
    width: 100% !important;
    display: block !important;
}

.reactDatePickerWrapper > * {
    display: block !important;
}

.setElementToCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.customLoader {
    width: 80px;
    height: 80px;
}

.tabItem:hover {
    cursor: pointer;
}

.grayBackgroundColor {
    background-color: #989898 !important;
}

.grayBackgroundColor > i {
    color: #ffffff !important;
}

.tableBtnWrapper {
    display: flex;
    justify-content: flex-end;
}

.tablePagingBtn {
    margin: 0px 10px 0px 10px;
    width: 40px !important;
    height: 35px !important;
}

.preloader {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*background:rgba(0,0,0,0.5) !important;*/
    width: 100%;
    position: fixed;
    z-index: 99999999999999999;
}

.defaultMarginTopBottom {
    margin-top: 15px;
    margin-bottom: 15px;
}

.snackbar-wrapper {
    position: fixed;
    right: 16px;
    z-index: 99999999999999999;
    transition: top 1s ease;
}

.snackbar-wrapper-open {
    top: 16px;
}

.snackbar-wrapper-close {
    top: -100px;
}

.displayNone {
    z-index: 9999999999 !important;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper > input {
    display: none;
}

.upload-btn-wrapper > .btn {
    width: 150px !important;
    height: 175px !important;
    background: #949494;
    border: 5px solid #dee2e6;
}

.upload-btn-wrapper > .btn > i {
    font-size: 30px;
    margin-top: 50px;
}

.upload-btn-wrapper > .btn > h5 {
    margin-top: 5px;
    font-weight: bold;
    font-size: 15px !important;
}

.display-image {
    margin-right: 5px !important;
    position: relative;
}

.display-image > img {
    height: 175px !important;
}

.image-hover {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image-hover > i {
    font-size: 23px;
    cursor: pointer;
}

.display-image:hover .select-image {
    opacity: 0.5;
}

.display-image:hover .image-hover {
    opacity: 1;
}

.user-profile-picture {
    height: 175px !important;
}

.order-create-div {
    border: 2px dashed #CED4DA;
    height: 250px;
    cursor: pointer;
}

.order-create-div > h4 {
    font-size: 20px !important;
}


.custom-logo-wrapper {
    position: relative;
    height: 50px;
}

.custom-logo-wrapper > img {
    position: absolute;
    height: 180px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.custom-logo-wrapper-inside-lg {
    position: relative;
    height: 50px;
}

.custom-logo-wrapper-inside-lg > img {
    position: absolute;
    height: 180px;
    left: 50%;
    top: 73%;
    transform: translate(-50%, -50%);
}

.custom-logo-wrapper-inside-sm {
    position: relative;
    height: 50px;
}

.custom-logo-wrapper-inside-sm > img {
    position: absolute;
    height: 75px;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
}

.signature-picture {
    height: 200px !important;
    transition: 0.3s;
    cursor: pointer;
}

.signature-picture:hover {
    opacity: 0.7;
}

.modalOrder {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

.modal-content-order {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

.closeOrderModal {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closeOrderModal:hover,
.closeOrderModal:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px){
    .modal-content-order {
        width: 100%;
    }
}

/*@media print {*/
/*    body * {*/
/*        visibility: hidden;*/
/*    }*/
/*    #test12345 * {*/
/*        visibility: visible;*/
/*    }*/
/*    #test12345 {*/
/*        position: absolute;*/
/*        left: 0;*/
/*        top: 0;*/
/*    }*/
/*}*/

@media screen {
    #printSection {
        display: none;
    }
}

@media print {
    body > *:not(#printSection) {
        display: none;
    }
    #printSection, #printSection * {
        visibility: visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
        padding: 5px;
        background-color: #FFFFFF;
    }
    #printSection > #print_preview {
        border: 1px solid black;
    }

    .printPreviewWrapper {
        padding: 10px;
    }
    .break_page
    {
        float: none !important; position: static !important; display: inline; 
        box-sizing: content-box !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
        border: 1px solid black;
    }

    .seperator {
        display:none;
    }
    .content {
        height: auto;
        /* width: 100%;  */
        display: flex; 
        /* flex-direction: row; */
      }
    .shipping-label-md-font {
        font-size: 18px;
    }

    .shipping-label-lg-font {
        font-size: 22px;
    }

}

.modalButton {
    position:absolute;
   top:10px;
   right: 40px;
}

@media only screen and (min-width: 1200px) {
    .card-height {
      width: 180px;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .datepicker-botton-margin-top {
      margin-top: 28px;
    }
  }

  .merchant_settlement_list_button{
    border: none;
    color: blue;
  }

  .terms_and_condition_btn{
    border: none;
    background: none;
    color: blue
  }

  .terms_and_condition_container{
    height: 400px;
    overflow: auto;
  }

  .terms_and_condition_line{
    display: flex;
  }